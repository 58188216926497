<template>
    <div>
        <el-divider></el-divider>
        <el-row type="flex" justify="space-around" align="middle">
            <div>
                {{ $t("lang.选择设备") }}<el-select v-model="selectSerialNumberObject" value-key="serialNumber" @change="calcData">
                    <el-option v-for="(item, index) in myCompanyDevice" :key="index" :label="item.serialNumber + '(' + item.remark + ')'" :value="item">
                    </el-option>
                </el-select>
            </div>
            <div>
                {{ $t("lang.选择日期") }} <el-date-picker v-model="searchRange" @change="calcData" type="datetimerange" :picker-options="pickerOptions" :range-separator="$t('lang.至')" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
                </el-date-picker>
            </div>
            <div>
                {{ $t("lang.时间间隔") + "(" + $t("lang.小时") + ")" }} <el-select v-model="selType" @change="calcData">
                    <el-option :value="1"></el-option>
                    <el-option :value="2"></el-option>
                    <el-option :value="3"></el-option>
                    <el-option :value="4"></el-option>
                    <el-option :value="5"></el-option>
                    <el-option :value="24"></el-option>
                </el-select>
            </div>
            <JsonExcel :data="tableData1" :fields="selectSerialNumberObject.deviceType == 'WXCW80' ? json_fields1 : json_fields" :name="exportFileName">
                <el-button type="primary" icon="el-icon-download"> {{ $t("lang.导出") }}</el-button>
            </JsonExcel>
        </el-row>
        <ve-table v-loading="loading" :element-loading-text="$t('lang.加载中')" element-loading-spinner="el-icon-loading" :columns="columns1" ref="table1" borderY style="margin-top:10px;" :table-data="tableData1" row-key-field-name="timeInterval" :max-height="myHeight"></ve-table>
    </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";

//导出相关
import JsonExcel from "vue-json-excel";


Vue.use(VueEasytable);

export default {
    components: { JsonExcel },
    data() {
        let that = this;
        return {
            myHeight: 0,
            loading: false,
            selType: 5,
            myCompanyDevice: [],
            selectSerialNumberObject: {},
            searchRange: [moment().startOf("day").toDate(), moment().startOf("day").add(1, "days").toDate()],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "1" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "2" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-1, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "3" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-2, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "7" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-6, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "15" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-14, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "30" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-29, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                ],
            },
            columns1: [
                { field: "timeInterval", key: "timeInterval", title: that.$t("lang.时间间隔"), width: 300 },
                { field: "t1", key: "t1", title: "T1(℃)", align: "right" },
                { field: "t2", key: "t2", title: "T2(℃)", align: "right" },
                { field: "t3", key: "t3", title: "T3(℃)", align: "right" },
                { field: "t4", key: "t4", title: "T4(℃)", align: "right" },
                { field: "t5", key: "t5", title: "T5(℃)", align: "right" },
                { field: "t6", key: "t6", title: "T6(℃)", align: "right" },
                { field: "t7", key: "t7", title: "T7(℃)", align: "right" },
                { field: "t8", key: "t8", title: "T8(℃)", align: "right" },
            ],
            tableData1: [],
            json_fields: { 时间间隔: "timeInterval", T1: "t1", T2: "t2", T3: "t3", T4: "t4", T5: "t5", T6: "t6", T7: "t7", T8: "t8" },
            json_fields1: { 时间间隔: "timeInterval", T1: "t1", T2: "t2", T3: "t3", T4: "t4" },
        };
    },
    computed: {
        exportFileName() {
            let that = this;
            if (that.searchRange) {
                return that.selectSerialNumberObject.serialNumber + "(" + moment(that.searchRange[0]).format("YYYY-MM-DD HH:mm:ss") + "-" + moment(that.searchRange[1]).format("YYYY-MM-DD HH:mm:ss") + ")";
            } else {
                return that.selectSerialNumberObject.serialNumber;
            }
        },
    },
    methods: {
        loadMyCompanyDevice() {
            let that = this;
            that.axios
                .post("WXCW03/GetMyCompanyDevice1", {
                    deviceType: "WXCW", //这里以后改成WXCW03
                })
                .then((response) => {
                    that.myCompanyDevice = response.data.data;
                });
        },
        calcData() {
            let that = this;
            if (that.selectSerialNumberObject.serialNumber && that.searchRange && that.selType) {
                that.loading = true;
                that.axios
                    .post("WXCW03_Result/CalcAvg", {
                        serialNumber: that.selectSerialNumberObject.serialNumber,
                        type: that.selType,
                        beginDate: that.searchRange[0],
                        endDate: that.searchRange[1],
                    })
                    .then((response) => {
                        that.loading = false;
                        if (response.data.code == 201) {
                            that.tableData1 = null;
                            that.$message({ type: "error", message: response.data.msg });
                        } else if (response.data.code == 101) {
                            that.tableData1 = response.data.data;
                        }
                    });
                that.$nextTick(function () {
                    if (that.selectSerialNumberObject.deviceType == "WXCW80") {
                        that.$refs["table1"].hideColumnsByKeys(['t5', 't6', 't7', 't8']);
                    }
                    else {
                        that.$refs["table1"].showColumnsByKeys(['t5', 't6', 't7', 't8']);
                    }
                });
            }
        },
    },
    mounted() {
        let that = this;
        that.myHeight = that.$parent.$el.offsetHeight - 155;
        that.loadMyCompanyDevice();
    },
};
</script>

<style></style>